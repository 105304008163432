<template>

	<div class="px-3">
	<v-list two-line no-gutter>
		<v-list-item v-for="item in items" :key="item.title" @click="snslink(item.links)" class="list">
			<v-list-item-avatar>
				<v-icon large :class="[item.iconClass]" v-text="item.icon"></v-icon>
			</v-list-item-avatar>
	
			<v-list-item-content>
				<v-list-item-title v-text="item.title"></v-list-item-title>
			</v-list-item-content>
	
			<v-list-item-action>
				<v-btn icon>
					<v-icon small color="">fas fa-chevron-right</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>
	</v-list>
	</div>
</template>

<script>
export default {
	data: () => ({
		items: [
			{ icon: 'fab fa-line', iconClass: 'graytext', title: 'LINEで紹介する', links: "" },
			{ icon: 'fab fa-twitter', iconClass: 'graytext', title: 'Twitterで紹介する', links: "" },
			{ icon: 'fab fa-facebook-square', iconClass: 'graytext', title: 'Facebookで紹介する', links: "" },
			{ icon: 'fas fa-envelope', iconClass: 'graytext', title: 'メールで紹介する', links: "" },
		]
	}),
	methods: {
		snslink(links) {
			this.$router.push(links);
		}
	}
}
</script>

<style scoped>
.list {
	padding: 0;
	border-bottom: 1px solid #DEDEDF;
}

.graytext {
	color: rgba(157, 157, 157, 0.6);
}
</style>